@import url(ag-grid-enterprise/styles/ag-grid.css);
@import url(ag-grid-enterprise/styles/ag-theme-balham.css);
@import url(ag-grid-enterprise/styles/ag-theme-quartz.css);
@import url(ag-grid-enterprise/styles/ag-theme-material.css);
@import url(ag-grid-enterprise/styles/ag-theme-alpine.css);
@import url(@digital-pulse/web-components/dist/pulse-stencil/pulse-stencil.css);


// Global Styling Variables and Classes

//======================================
// 0. Contents
//  -- 1. Imports
//  -- 2. Variables
//  -- 3. Typography (Heading, Paragraph, Display)
//  -- 4. Misc
//======================================

//======================================
// 1. Imports
//======================================
@import "@digital-pulse/web-components-ng/dist/component-library/styles/global.css";

@font-face {
  font-family: 'CVS Health Sans';
  src: url(assets/font/CVSHealthSansVF_W_Wght.woff2) format('woff2');
  font-weight: 1 1000;
  font-style: normal;
}

//======================================
// 2. Variables
//======================================
:root {
  //======================================
  // 2.1 Theme Override Variables
  //======================================
  --ps-button-solid-color-background: #004D99;
  --ps-theme-color-action-default: #004D99;
  --ps-theme-color-action-variant: #1A1A19;
  --ps-theme-color-action-hover: #004D99;
  --ps-theme-color-action-active: #0066CC;
  --ps-theme-color-action-visited: #767676;
  --ps-theme-color-background-disabled: #CCCCCC;
  --ps-theme-color-border-interactive: #1A1A19;
  --ps-theme-color-border-decorative: #CCCCCC;
  --ps-theme-color-border-disabled: #767676;
  --ps-theme-color-border-inactive: #767676;
  --ps-theme-color-brand-primary: #004D99;
  --ps-theme-color-container: #FFFFFF;
  --ps-theme-color-content-default: #262626;
  --ps-theme-color-content-disabled: #474747;
  --ps-theme-color-contrast: #000000;
  --ps-theme-color-info: #1171E6;
  --ps-theme-color-inverse: #000000;
  --ps-theme-color-negative: #DB3321;
  --ps-theme-color-page: #FFFFFF;
  --ps-theme-color-positive: #118738;
  --ps-theme-color-section: #F7F7F7;
  --ps-theme-color-scrim: rgba(0, 0, 0, 0.65);
  --ps-theme-color-warning: #E07800;
  --ps-button-corner-radius: 2rem;
  font-size: 16px;

  //======================================
  // 2.2 Easy Rems Variables
  //======================================
  --px-328: 20.5rem;
  --px-192: 12rem;
  --px-92: 5.75rem;
  --px-64: 4rem;
  --px-56: 3.5rem;
  --px-48: 3rem;
  --px-40: 2.5rem;
  --px-36: 2.25rem;
  --px-32: 2rem;
  --px-28: 1.75rem;
  --px-26: 1.625rem;
  --px-24: 1.5rem;
  --px-22: 1.375rem;
  --px-20: 1.25rem;
  --px-18: 1.125rem;
  --px-16: 1rem;
  --px-14: 0.875rem;
  --px-12: 0.75rem;
  --px-10: 0.625rem;
  --px-8: 0.5rem;
  --px-6: 0.375rem;
  --px-4: 0.25rem;
  --px-2: 0.125rem;

  //======================================
  // 2.3 Breakpoint Variables
  //
  // https://cvsdigital.atlassian.net/wiki/spaces/CUK/pages/2314207331/Web+-+Grid+Screen+Sizes#:~:text=behavior%20is%20intentional.-,Viewport,-width
  // 4 column < 768px, 8 column 768-1024px, 12 column > 1024px
  //======================================
  --breakpoint-mobile-max-width: 767px;
  --breakpoint-tablet-min-width: 768px;
  --breakpoint-tablet-max-width: 1024px;
  --breakpoint-desktop-min-width: 1025px;

  //======================================
  // 2.4 Typography Variables
  //======================================
  --cvs-font-weight-light: 300;
  --cvs-font-weight-regular: 400;
  --cvs-font-weight-medium: 500;
  --cvs-font-weight-semi-bold: 600;
  --cvs-font-weight-bold: 700;
  --cvs-font-weight-extra-bold: 800;
  --cvs-heading-line-height: 1.3;
  --cvs-paragraph-line-height: 1.5;
  --cvs-display-line-height: 1.2;
}

//======================================
// 3. Typography
//
// https://cvsdigital.atlassian.net/wiki/spaces/CUK/pages/3267985410/CVS+Styles+-+Typography
//======================================

//======================================
// 3.1 Headings
//
// https://cvsdigital.atlassian.net/wiki/spaces/CUK/pages/3267985410/CVS+Styles+-+Typography#Heading
//======================================

// Heading XXXL
.heading-xxxl {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-36);
  font-weight: var(--cvs-font-weight-medium);
}

// Heading XXL
.heading-xxl {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-32);
  font-weight: var(--cvs-font-weight-medium);
}

// Heading XL
.heading-xl {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-24);
  font-weight: var(--cvs-font-weight-semi-bold);
}

// Heading L
.heading-l {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-20);
  font-weight: var(--cvs-font-weight-semi-bold);
}

// Heading M
.heading-m {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-18);
  font-weight: var(--cvs-font-weight-semi-bold);
}

// Heading S
.heading-s {
  line-height: var(--cvs-heading-line-height);
  font-size: var(--px-16);
  font-weight: var(--cvs-font-weight-semi-bold);
}

//======================================
// 3.2 Paragraph
//
// https://cvsdigital.atlassian.net/wiki/spaces/CUK/pages/3267985410/CVS+Styles+-+Typography#Paragraph
//======================================

// Paragraph XL w/ regular weight(400)
.paragraph-xl {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-22);
  font-weight: var(--cvs-font-weight-regular);
}

// Paragraph XL w/ medium weight(500)
.paragraph-xl-strong {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-22);
  font-weight: var(--cvs-font-weight-medium);
}

// Paragraph L w/ regular weight(400)
.paragraph-l {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-18);
  font-weight: var(--cvs-font-weight-regular);
}

// Paragraph L w/ medium weight(500)
.paragraph-l-strong {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-18);
  font-weight: var(--cvs-font-weight-medium);
}

// Paragraph M w/ regular weight(400)
.paragraph-m {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-16);
  font-weight: var(--cvs-font-weight-regular);
}

// Paragraph M w/ medium weight(500)
.paragraph-m-strong {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-16);
  font-weight: var(--cvs-font-weight-medium);
}

// Paragraph S w/ regular weight(400)
.paragraph-s {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-14);
  font-weight: var(--cvs-font-weight-regular);
}

// Paragraph S w/ medium weight(500)
.paragraph-s-strong {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-14);
  font-weight: var(--cvs-font-weight-medium);
}

// Paragraph XS w/ regular weight(400)
.paragraph-xs {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-12);
  font-weight: var(--cvs-font-weight-regular);
}

// Paragraph XS w/ medium weight(500)
.paragraph-xs-strong {
  line-height: var(--cvs-paragraph-line-height);
  font-size: var(--px-12);
  font-weight: var(--cvs-font-weight-medium);
}

//======================================
// 3.3 Display
//
// https://cvsdigital.atlassian.net/wiki/spaces/CUK/pages/3267985410/CVS+Styles+-+Typography#Display
//======================================

// Display L
.display-l {
  line-height: var(--cvs-display-line-height);
  font-size: var(--px-56);
  font-weight: var(--cvs-font-weight-regular);
}

// Display M
.display-m {
  line-height: var(--cvs-display-line-height);
  font-size: var(--px-56);
  font-weight: var(--cvs-font-weight-regular);
}

// Display S
.display-s {
  line-height: var(--cvs-display-line-height);
  font-size: var(--px-56);
  font-weight: var(--cvs-font-weight-regular);
}

// Display XS
.display-xs {
  line-height: var(--cvs-display-line-height);
  font-size: var(--px-56);
  font-weight: var(--cvs-font-weight-regular);
}

//======================================
// 4. Misc.
//======================================

.app-breadcrumb {
  max-width: 90rem;
  margin: auto;
}

.app-page {
  margin: var(--ps-theme-space-xl) auto;
  display: flex;
  gap: 2rem;
  max-width: 90rem;

  .app-page-main {
    display: flex;
    flex-direction: column;
    gap: var(--ps-theme-space-l);
    flex: 1;
  }

  .app-page-aside {
    display: flex;
    flex-direction: column;
    gap: var(--ps-theme-space-xl);
    width: 26.5rem;
  }

  .app-page-image {
    height: 100%;
    width: 100%;
  }
}

main {
  height: calc(100vh - 9.25rem) !important;
  padding: .5rem 2rem 0 !important;
  overflow: visible;
}

footer {
  background: black;
  color: white;
  margin-left: -25px;
  margin-right: -24px;
}

::ng-deep cvs-base span[data-test="footer-text"] {
  display: none !important;
}

.ps-select select.focus-visible,
.ps-select select:focus-visible {
  outline: unset;
  outline-offset: unset;
}

.main-wrapper {
  overflow: visible !important;
}

// .internal-pulse-theme {
//   a {
//     color: var(--ps-link-color-text);
//     &:visited {
//       color: var(--ps-link-color-text-visited);
//     }
//   }

//   body {
//     h1 {
//       font-size: 1.57rem;
//       font-weight: 600;
//     }
//     h2 {
//       font-size: 1.28rem;
//       font-weight: 600;
//     }
//     h3 {
//       font-size: 1.14rem;
//       font-weight: 400;
//     }
//   }
// }
.internal-pulse-theme {
  #program-management-table, lib-benefits-plans-overview {

    .ag-icon.ag-icon-checkbox-unchecked, .ag-checkbox-input-wrapper:not(.ag-checked,.ag-disabled) {
      border-radius: 4px;
      width: 16px;
      height: 16px;
      background-size: unset;
      border: 2px solid #1A1A19;
      background: #FFF;
      input {
        margin-bottom: 1rem;
      }
    }
    .ag-icon.ag-icon-checkbox-checked, .ag-checkbox-input-wrapper.ag-checked {
      border-radius: 4px;
      width: 16px;
      height: 16px;
      border: 2px solid #1A1A19;
      input {
        margin-bottom: 1rem;
      }
    }
    
  }
  .cpm-details-container {
    .ag-icon.ag-icon-checkbox-unchecked, .ag-checkbox-input-wrapper:not(.ag-checked,.ag-disabled) {
      border-radius: 2px;
      width: 16px;
      height: 16px;
      background-size: unset;
      border: 1px solid #1A1A19;
      background: #FFF;
    }
    .ag-icon.ag-icon-checkbox-checked, .ag-checkbox-input-wrapper.ag-checked {
      border-radius: 2px;
      width: 16px;
      height: 16px;
      border: 1px solid #1A1A19;
    }
    
  }
}

.internal-pulse-theme {
.ag-checkbox-input-wrapper.ag-disabled {
  border-radius: 2px;
  width: 16px;
  height: 16px;
  border: 1px dashed #000000;
  background: #F9F7F5
}
}

.mypbm-row {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  .column-small {
    width: 330px;
    height: 330px;
  }
  .column-medium {
    width: 684px;
    height: 330px;
  }
  .column-large {
    width: 1038px;
    height: 330px;
  }
  .column-x-large {
    width: 1392px;
    height: 330px;
  }
}

ps-tile {
  height: 100% !important;
}
#main-cvs-content {
  .cdk-visually-hidden {
    display: none;
  }
}
